.about-us-container {
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1950px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.image-overlay-container {
  position: relative;
  width: 100%;
  height: 60vh; /* Set height to 50vh */
  overflow: hidden;
}

.foundation-image {
  width: 100%; /* Ensure the image covers the container */
  height: 60vh; /* Set height to 50vh */
  object-fit: cover;
  position: relative;
  border-radius: 4rem;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 1) 100%
  );
}

.about-us-content {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: left;
  color: white;
  padding: 20px;
  width: 80%;
  max-width: 50%;
  min-width: 300px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.about-us-content h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 3em;
  font-weight: 900;
  line-height: 1.1;
  color: white;
  margin-bottom: 20px;
}

.about-us-content p {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.6;
  color: white;
  margin-bottom: 40px;
}

.meet-the-team-button {
  font-size: 1em;
  font-weight: bold;
  color: black;
  background-color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.meet-the-team-button:hover {
  background-color: #f0f0f0e0;
  font-size: 1.1rem;
}

@media (max-width: 1100px) {
  .about-us-container {
    margin-top: 125px;
    margin-bottom: 125px;
  }

  .image-overlay-container {
    height: auto;
  }

  .foundation-image {
    height: auto;
  }

  .about-us-content {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    max-width: 90%;
    text-align: center;
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 1rem;
  }

  .image-overlay {
    background: none;
  }
}
