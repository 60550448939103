.media-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 80px;
  max-width: 1800px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.section-title {
  font-size: 3rem;
  position: absolute;
  font-family: "Bebas Neue", sans-serif;
  top: 5px;
  left: 10px;
  font-weight: 1200;
  color: white;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center cards horizontally */
  transition: transform 0.3s ease-in-out;
}

.card {
  width: 30%; /* Adjust width to fit 3 cards per row */
  box-sizing: border-box;
  margin: 1.5%; /* Space between cards */
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.card-image-container {
  position: relative;
  overflow: hidden;
}

.card-img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.card:hover .card-img {
  transform: scale(1.01);
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%); /* Hide content initially */
}

.card:hover .card-content {
  transform: translateY(0); /* Slide up content on hover */
}

.card-title {
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold;
  margin-bottom: 10px;
}

.card-description {
  margin-top: 5px;
  font-size: 1rem; /* Adjust font size as needed */
  display: none; /* Hide description initially */
}

.card:hover .card-description {
  display: block; /* Show description on hover */
}

.nav-button {
  position: absolute;
  top: 57.5%;
  transform: translateY(-50%);
  background: #fff;
  color: #000;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.nav-button.prev {
  left: 40px;
  font-size: larger;
}

.nav-button.next {
  right: 40px;
  font-size: larger;
}

.nav-button:hover {
  background: #f0f0f0;
}

/* Mobile adjustments */
@media (max-width: 1000px) {
  .card {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }

  .nav-button {
    top: 80%;
  }
}
