@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background-color: black;
  color: white;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  
}

/* NAV BAR WITH THE DESKTOP VIEW */


.desktop-nav .menu-items a,
.mobile-nav .menu-items a,
.sidebar .menu-items a {
  font-family: 'Bebas Neue', sans-serif; /* Apply Bebas Neue to menu item links */
  display: block;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.nav-area {
  display: flex;
  font-family: 'Bebas Neue', sans-serif;
  justify-content: space-between; /* Added to space between logo/menu and icons */
  background-color: black;
  align-items: center;
  padding: 10px 20px;
  max-width: 1700px;
  margin: 0 auto;
  font-size: 1.5rem;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  margin-left: 20px; /* Space between icons */
  text-decoration: none;
}

.social-icons a:hover {
  color: #ddd; /* Change color on hover */
}

.logo img {
  margin-right: 20px;
  max-height: 85px; /* Adjust this value as needed */
  width: auto;
}

/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 1.5rem;
}

.desktop-nav .menu-items a {
  display: block;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: white;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items > a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  background-color: #333; /* Slightly lighter black for hover effect */
}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid white;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: auto;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: black;
  border-radius: 0.5rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.desktop-nav .dropdown a {
  color: white;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  text-decoration: none;
}

/* Hover effects for dropdown items */
.desktop-nav .dropdown a:hover {
  background-color: #333; /* Slightly lighter black for hover effect */
  font-weight: bold;
  font-size: 1.5rem; /* Adjust size as needed */
}

/* page content */
.content {
  margin: 0 auto;
  padding: 3rem 7rem;
}

.content h1 {
  font-size: 2rem;
}

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

/* Mobile menu styles */
.mobile-nav {
  display: none;
}

/* SIDEBAR */

/* Sidebar navigation styles */
.sidebar {
  font-family: 'Bebas Neue', sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background-color: black;
  color: white;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar.show {
  transform: translateX(0);
}

.sidebar .close-button {
  align-self: flex-end;
  background: transparent;
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
}

.sidebar .menu-items {
  list-style: none;
  padding: 0;
}

.sidebar .menu-items a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px 0;
  font-size: 1.2rem;
}

.sidebar .menu-items a:hover {
  background-color: #333;
}

.sidebar .social-icons {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}

.sidebar .social-icons a {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
}

/* FOR MOBILE SCREEN AND SMALLER WINDOW SCREENS */
@media screen and (max-width: 1050px) {
  .nav-area {
    justify-content: space-between;
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
  }

  .mobile-nav__menu-button {
    position: relative;
    width: 30px;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .mobile-nav__menu-button span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: white;
  }

  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 50px;
    right: 20px;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: black;
    border-radius: 0.5rem;
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: white;
    /* font-size: inherit; */
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1.5rem;
  }

  /* Hover effects for mobile devices */
  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-color: #333; /* Slightly lighter black for hover effect */
    /* font-weight: bold; */
    font-size: 1.5rem; /* Adjust size as needed */
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.5em;
    vertical-align: 0.09em;
    border-top: 0.42em solid white;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid white;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }

  .mobile-nav .dropdown a {
    color: white;
    padding: 0.7rem 1rem;
    text-decoration: none;
    font-size: 1rem;
  }

  .mobile-nav .dropdown a:hover {
    background-color: #333; /* Slightly lighter black for hover effect */
    font-weight: bold;
    font-size: 1.3rem; /* Adjust size as needed */
  }
}


