.video-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  margin: 1rem auto;
}

.video-page-container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-page-container-header h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.video-content {
  display: flex;
  width: 90%;
  height: 500px;
  margin-bottom: 8rem;
}

.video-player {
  flex: 2;
  display: flex;
}

.video-player > div {
  margin: 0;
  padding: 0;
}

.video-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-left: 1rem;
}

.video-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.video-item:hover {
  background-color: #f0f0f0e0;
}

.video-item:hover p {
  color: black;
}

.video-item img {
  width: 100px;
  height: 65px;
  object-fit: cover;
  margin-left: 1.5rem;
}

.video-item p {
  color: white;
  font-size: 0.875rem;
  flex: 1;
}

.video-item.active {
  background-color: #f0f0f0e0;
}

.video-item.active p {
  color: black;
}

.video-slider {
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative; /* Add this */
}

.video-slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.video-slider-header h2 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.5rem;
}

.video-slider-videos {
  display: flex;
  overflow: hidden;
  position: relative;
}

.slider-video-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.image-wrapper img {
  width: 90%;
  height: 180px;
  object-fit: cover;
}

.text-wrapper {
  margin-top: 0.5rem;
}

.text-wrapper p {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  text-align: center;
}

.slider-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  z-index: 1; /* Add this */
}

.slider-nav-button.prev {
  left: 0; /* Adjust position */
}

.slider-nav-button.next {
  right: 0; /* Adjust position */
}

.slider-nav-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media screen and (max-width: 1100px) {
  .video-content {
    flex-direction: column;
    height: 1000px;
  }

  .video-list {
    margin-left: 0;
    margin-top: 1rem;
  }
}
