/* src/Components/Photos/PhotosPage.css */
.photos-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  margin: 1rem auto;
}

.photos-page-container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photos-page-container-header h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.gallery-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.gallery-cards-container h2{
  font-size: 1.3rem;
  color: white;
}

.gallery-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.gallery-card img {
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.gallery-card h2 {
  margin-top: 0.5rem;
  font-family: "Bebas Neue", sans-serif;
}
