.latest-news-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  
  margin: 1rem auto;
}

.latest-news-container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.latest-news-container-header h1 {
  font-size: 2.5rem;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 3rem;
}

.news-items-container {
  display: grid;
  
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 90%;
}

/* Medium screens (tablets, 768px and up) */
@media (max-width: 1200px) {
  .news-items-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small screens (phones, 600px and up) */
@media (max-width: 768px) {
  .news-items-container {
    grid-template-columns: 1fr;
  }
}
