.gallery-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 1rem auto;
  position: relative;
}

.gallery-title {
  align-self: flex-start;
  margin-bottom: 2rem;
  font-family: "Bebas Neue", sans-serif;
  font-size: 2rem;
}

.gallery-slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
  height: 700px; /* Fixed height */
}


.gallery-image-container {
  max-width: 800px;
  max-height: 600px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height */
}

.gallery-slider-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.gallery-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  color: #000;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.gallery-nav-button.prev {
  left: 0; /* Changed to 0 for edge positioning */
}

.gallery-nav-button.next {
  right: 0; /* Changed to 0 for edge positioning */
}

.gallery-nav-button:hover {
  background: #f0f0f0;
}

.back-to-galleries-button {
  margin-top: 1rem;
  background-color: white;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Bebas Neue", sans-serif;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-to-galleries-button:hover {
  background-color: #f0f0f0e0;
}
