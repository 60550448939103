.news-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  margin: 1rem auto;
}

.news-container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.news-container-header h1 {
  font-size: 2.5rem;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 3rem;
}

.news-section {
  width: 90%;
}

.main-news {
  flex: 1;
  margin-right: 1rem;
}

.main-news h2 {
  font-size: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-weight: bolder;
  color: rgba(255, 255, 255, 0.819);
}

.main-news p {
  font-size: 0.75rem;
  color: lightgray;
}

.additional-news {
  flex: 1;
  margin-left: 1rem;
}

.supplement-article {
  display: flex;
  align-items: flex-start; /* Align items to the top of the container */
  border-bottom: 1px solid lightgrey;
  margin-bottom: 25px;
  padding-bottom: 0.625rem;
  width: 100%;
}

.article-content {
  flex: 1; /* Ensures the title takes up the remaining space */
  margin-right: 20px;
  display: flex;
  align-items: flex-start; /* Align text to the top */
}

.article-image {
  display: flex;
  align-items: flex-start; /* Align image to the top */
}

.article-image img {
  width: 150px;
  height: auto;
  flex-shrink: 0; /* Prevents the image from shrinking */
}

.supplement-article h3 {
  margin-right: 1rem;
  font-size: 1.1rem; /* Adjust the font size as needed */
  color: rgba(255, 255, 255, 0.819);
}

.supplement-article-link,
.main-news-link {
  text-decoration: none;
  color: inherit;
}

.morenews-button {
  display: flex;
  justify-content: flex-end;
  width: 90%;
}

.all-news {
  margin: 1rem;
  padding: 5px 10px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.all-news:hover {
  background-color: #f0f0f0e0;
}
